<template>
    <div>
        <b-modal
            id="edit-push-notification-modal"
            hide-footer
            size="md"
            centered
            title="Edit Notification Type"
            @hidden="resetModal"
        >
            <div class="modal_content">
                <b-alert
                    dismissible
                    :show="hasError"
                    class="p-1"
                    variant="danger"
                    @dismissed="clearErrorMsg"
                    >{{ errorMsg }}</b-alert
                >
                <p class="mb-0">Notification Type</p>
                <b-form-select class="mb-1" disabled="true" v-model="record.notificationType" :options="formattedNotificationTypes"
                    value-field="value" text-field="text" v-on:change="onChangeNotificationType"/>
                <p class="mb-0">Trigger Name</p>
                <!-- :disabled="record.notificationType == '' ? true : false" -->
                <b-form-select class="mb-1" disabled="true"
                    v-model="record.whenTrigger"
                    :options="getNotificationTypeName(record.notificationType) ? activeProfileTriggerNames : nonActiveTriggerName" />
                <p class="mb-0">Time</p>
                <b-form-input class="mb-1" v-model="record.time" placeholder="Time (hours)" type="number" min="0" />
                <p class="mb-0">Message</p>
                <b-form-textarea
                    class="mb-1"
                    v-model="record.message"
                    rows="6"
                    placeholder="Enter a Message"
                ></b-form-textarea>
                <p class="mb-0">Mascot</p>
                <input
                    ref="ref_Input_mascort"
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg application/json, .json"
                    class="d-none"
                    @change="ImageRendererItems"
                />
                <b-dropdown v-if="record.mascort == '' || record.mascort == null"  variant="link" no-caret>
                    <template #button-content>
                        <feather-icon
                            icon="UploadIcon"
                            size="50"
                            class="qu-icon-grey curser-pointer"
                        />
                    </template>
                    <b-dropdown-item @click="triggerFileInput()">
                        <feather-icon icon="UploadIcon" />
                        <span class="align-middle ml-50">Upload</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="showGalleryImages">
                        <feather-icon icon="ImageIcon" />
                        <span class="align-middle ml-50">Gallery Images</span>
                    </b-dropdown-item>
                </b-dropdown>
                <template v-else>
                    <img
                        :src="record.mascort"
                        height="80"
                        :alt="record.mascort"
                        @click="showImageInModel()"
                    />
                </template>

                <p class="mb-0">Personalized</p>
                <b-form-input
                    class="mb-1"
                    v-model="record.personalized"
                    placeholder="Personalized"
                    type="text"
                />

                <p class="mb-0">Condition</p>
                <b-form-input
                    class="mb-1"
                    v-model="record.condition"
                    placeholder="Condition"
                    type="text"
                />
                <div class="modal-footer">
                    <b-button
                        @click="updateTheme"
                        :disabled="loading"
                        variant="primary"
                    >
                        <b-spinner v-if="loading" small class="mr-1" />
                        Update
                    </b-button>
                </div>
            </div>
        </b-modal>
        <b-modal v-model="showFullScreen" size="lg">
            <b-button variant="primary" @click="triggerFileInput()">
                Upload
            </b-button>
            <b-button class="ml-1" variant="primary" @click="showGalleryImages">
                Gallery Images
            </b-button>
            <b-button
                v-if="record.mascort"
                class="ml-1"
                variant="danger"
                @click="removeImage"
            >
                Remove
            </b-button>
            <div class="text-center mt-2">
                <img
                    v-if="record.mascort"
                    :src="record.mascort"
                    alt="Fullscreen"
                    style="height: auto; max-width: 100%"
                />
                <feather-icon
                    v-else
                    @click="triggerFileInput()"
                    icon="UploadIcon"
                    size="50"
                    class="qu-icon-grey curser-pointer"
                />
            </div>
        </b-modal>
        <zape-gallery-model
            :props_hideShowModel="showImage"
            @modelClose="modelClose"
            @saveCategoryImage="saveCategoryImage"
        />
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormGroup,
    BAlert,
    BSpinner,
    BDropdownItem,
    BFormTextarea,
    BFormSelect,
    BDropdown,
} from "bootstrap-vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        ZapeGalleryModel: () => import("../Model/ZapeGalleryModel.vue"),
        BButton,
        BFormInput,
        BFormGroup,
        BAlert,
        BSpinner,
        BDropdownItem,
        BFormTextarea,
        BFormSelect,
        BDropdown,
        ToastificationContent,
    },
    data() {
        return {
            notificationTypeName: "",
            notificationDescription: "",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                whenTrigger: "",
                time: "",
                message: "",
                mascort: "",
                personalized: "",
                condition: "",
                notificationType: "",
            },
            activeProfileTriggerNames: [
                "He is in top 20 (1-20)",
                "He is at Rank 10",
                "He ranked up if he is in top 10 ( 1-9).",
                "If he is top 5. (1-5)",
                "If he is top 3 (1-3)",
                "He appears on the Honor board.",
                "He left his level incomplete; notify him.",
                "He doesn’t show up; inform him before his time of usage.",
                "He is the most active user and completed X subject, more than 70%, but didn’t complete his level or didn’t show up.",
                "Streak alert/countdown before 1 hour and If he is not active for 24h",
                "Streak is broken",
                "He is active, but just for engagement purposes.",
                "Approved leaderboard",
                "Decline leaderboard"
            ],
            nonActiveTriggerName: [
                "When he signed in but did not use it at all",
                "Day 1",
                "Day 2",
                "Day 3",
                "Day 4",
                "Day 5",
                "Day 6",
                "Day 7",
            ],
            showFullScreen: false,
            showImage: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
        formattedNotificationTypes() {
            return this.notificationTypes.map(item => ({
                value: item._id,
                text: item.name
            }));
        },
    },
    watch: {
        notificationData: function (newVal) {
            this.record.notificationType = this.notificationData.notificationTypeId;
            this.record.whenTrigger = this.notificationData.triggerName;
            this.record.time = this.notificationData.time;
            this.record.message = this.notificationData.message;
            this.record.mascort = this.notificationData.mascort;
            this.record.personalized = this.notificationData.personalized;
            this.record.condition = this.notificationData.condition;
        },
    },
    methods: {
        onChangeNotificationType(){
            this.record.whenTrigger = "";
        },
        getNotificationTypeName() {
            const result = this.notificationTypes.find(item => item._id === this.record.notificationType);
            return !result || result.name.toLowerCase() === 'active';
        },
        async ImageRendererItems(event) {
            const file = event.target.files[0];

            if (file) {
                const fileType = file.type;
                const fileExtension = this.getFileExtension(fileType);
                try {
                    const response = await axios.post(
                        process.env.VUE_APP_API_URL +
                            "/s3/generate-presigned-url",
                        {
                            type: file.type,
                            ext: fileExtension,
                        },
                        {
                            headers: {
                                Authorization: this.token,
                            },
                        }
                    );
                    const { url, key } = response.data;

                    let extention =
                        fileExtension == "json" ? "lottie" : fileExtension;
                    await this.uploadFile(file, url, key, extention);
                } catch (error) {
                    console.error("Error getting pre-signed URL:", error);
                }
            }
        },
        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error("Error uploading file:", response);
                }
                let s3Url =
                    "https://zape02.s3.ap-southeast-1.amazonaws.com/" + key;
                this.record.mascort = s3Url;
                this.createGalleryImage(s3Url, file.name, ext);
            } catch (error) {
                console.error("Error uploading file:", error);
            }
        },
        async createGalleryImage(s3Url, fileName, ext) {
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url,
                    imageFormat: ext,
                };
                await axios.post(
                    process.env.VUE_APP_API_URL + "/gallery/image/create",
                    galleryObj,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    }
                );
            } catch (error) {
                console.error("Error saving image to gallery", error);
            }
        },
        getFileExtension(mimeType) {
            const mimeToExtension = {
                "image/jpeg": "jpg",
                "image/png": "png",
                "image/gif": "gif",
                "application/json": "json",
                ".json": "json",
            };
            return mimeToExtension[mimeType] || "png";
        },

        showGalleryImages() {
            this.showImage = true;
        },
        modelClose(modelState) {
            this.showImage = modelState;
        },
        removeImage() {
            this.record.mascort = null;
        },
        saveCategoryImage(categoryImage) {
            this.record.mascort = categoryImage;
        },
        triggerFileInput() {
            this.$refs.ref_Input_mascort.click();
        },
        showImageInModel() {
            this.showFullScreen = true;
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.record = {
                whenTrigger: '',
                time: '',
                message: '',
                mascort: '',
                personalized: '',
                condition: '',
            }
            this.errorMsg = "";
            this.$emit("removeThemeData");
        },
        async updateTheme() {
            let status = await this.checkValidation();
            if (!status) {
                return;
            }

            this.loading = true;
            this.record._id = this.notificationData._id;
            try {
                const resp = await axios.put(
                    process.env.VUE_APP_API_URL + "/push-notification",
                    this.record,
                    {
                        headers: {
                            Authorization: this.token,
                        },
                    }
                );

                if (resp.data.status === 200) {
                    this.alertMessageToast = resp.data.message;
                    this.showToast("success");
                    this.$emit("fetchThemes");
                    this.$bvModal.hide("edit-push-notification-modal");
                    this.loading = false;
                }
            } catch (error) {
                if (error.response) {
                    this.errorMsg =
                        error.response.data.error || "An error occurred";
                } else if (error.request) {
                    this.errorMsg =
                        "No response received from server. Please check your network connection.";
                } else {
                    this.errorMsg =
                        "Error in setting up the request: " + error.message;
                }
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            console.log("this.record",this.record);
            if (!this.record.notificationType.length) {
                this.errorMsg = "Notification Type required.";
                return;
            }

            if (!this.record.whenTrigger.length) {
                this.errorMsg = "Trigger name required.";
                return;
            }

            if (this.record.time < -1) {
                this.errorMsg = "Time required.";
                return;
            }

            if (!this.record.message.length) {
                this.errorMsg = "Message required.";
                return;
            }

            if (!this.record.mascort.length) {
                this.errorMsg = "Mascort required.";
                return;
            }
            return "ok";
        },
    },
    props: ["token", "notificationData","notificationTypes"],
};
</script>
